<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<securityforms-form-header :modelPk="modelPk"></securityforms-form-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col xs12 lg12>
					<v-row dense>
						<!-- PORTCALLID -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="padding-top: 20px">
							<pui-select
								:id="`portcallid-securityforms`"
								:attach="`portcallid-securityforms`"
								:label="$t('securityforms.portcallid')"
								:disabled="!this.isCreatingElement || this.$route.params.parentpk"
								required
								toplabel
								clearable
								v-model="model"
								modelName="vlupportcalls"
								:modelFormMapping="{ portcallid: 'portcallid' }"
								:itemsToSelect="portcallidItemsToSelect"
								:itemValue="['portcallid']"
								:itemText="(item) => `${item.visitid}`"
								:order="{ portcallid: 'desc' }"
								:fixedFilter="filterPortcalls"
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense style="margin-top: -15px">
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-field-set :title="$t('securityforms.fieldset.ship')">
								<v-row dense>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-text-field
											:id="`securityforms-shipname`"
											v-model="model.portcall.mship.shipname"
											:label="$t('securityforms.shipname')"
											disabled
											toplabel
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`securityforms-imo`"
											v-model="model.portcall.mship.imo"
											:label="$t('securityforms.imo')"
											disabled
											toplabel
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`securityforms-flag`"
											attach="securityforms-flag"
											:label="$t('securityforms.flag')"
											toplabel
											disabled
											clearable
											reactive
											v-model="model.portcall"
											modelName="mcountries"
											:modelFormMapping="{ countrycode: 'countrycode' }"
											:itemsToSelect="itemsToSelectFlag"
											:itemValue="['countrycode']"
											:itemText="(item) => `${item.countrydesc}`"
											:order="{ countrydesc: 'asc' }"
										></pui-select>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`securityforms-callsign`"
											v-model="model.portcall.mship.callsign"
											:label="$t('securityforms.callsign')"
											disabled
											toplabel
										></pui-text-field>
									</v-col>
								</v-row>
								<v-row dense style="margin-top: -15px">
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`securityforms-shiptype`"
											attach="securityforms-shiptype"
											:label="$t('securityforms.shiptype')"
											toplabel
											clearable
											disabled
											reactive
											v-model="model.portcall.mship"
											modelName="mshiptypes"
											:modelFormMapping="{ shiptypecode: 'shiptypecode' }"
											:itemsToSelect="itemsToSelectShipType"
											:itemValue="['shiptypecode']"
											:itemText="(item) => `${item.shiptypedesc}`"
											:order="{ shiptypedesc: 'asc' }"
										></pui-select>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`securityforms-grosstonnage`"
											v-model="model.portcall.mship.grosstonnage"
											:label="$t('securityforms.grosstonnage')"
											toplabel
											disabled
											type="number"
											suffix="t"
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`securityforms-registryport`"
											attach="securityforms-registryport"
											:label="$t('securityforms.registryport')"
											toplabel
											disabled
											reactive
											clearable
											v-model="model.portcall.mship"
											modelName="mports"
											:modelFormMapping="{ countrycode: 'certregistrycountry', portcode: 'certregistryport' }"
											:itemsToSelect="itemsToSelectRegistryPort"
											:itemValue="['countrycode', 'portcode']"
											:itemText="(item) => `${item.locode} - ${item.portdescription}`"
											:order="{ locode: 'asc' }"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense style="margin-top: -15px">
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<!-- COMPANYNAME -->
										<pui-text-field
											:id="`companyname-securityforms`"
											v-model="model.portcall.mship.owneroperator"
											:label="$t('securityforms.companyname')"
											disabled
											required
											toplabel
											maxlength="70"
										></pui-text-field>
									</v-col>
									<!-- COMPANYIMONR -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`companyimonr-securityforms`"
											v-model="model.companyimonr"
											:label="$t('securityforms.companyimonr')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
											type="number"
											maxlength="7"
											min="0000001"
											max="9999999"
											length="7"
											:rules="[($v) => ($v && $v.length === 7) || 'The IMO company value must be 7 characters long']"
											@keydown="handleKeyDown"
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`securityforms-inmarsatcallnr`"
											v-model="model.portcall.mship.inmarsatcallnr"
											:label="$t('securityforms.inmarsatcallnr')"
											toplabel
											disabled
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-field-set :title="$t('securityforms.fieldset.agent')">
								<v-row dense>
									<!-- AGENTNAME -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-text-field
											:id="`agentname-securityforms`"
											v-model="model.agentname"
											:label="$t('securityforms.agentname')"
											disabled
											required
											toplabel
											maxlength="50"
										></pui-text-field>
									</v-col>
									<!-- AGENTPHONE -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`agentphone-securityforms`"
											v-model="model.agentphone"
											:label="$t('securityforms.agentphone')"
											disabled
											toplabel
											maxlength="20"
										></pui-text-field>
									</v-col>
									<!-- AGENTFAX -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`agentfax-securityforms`"
											v-model="model.agentfax"
											:label="$t('securityforms.agentfax')"
											disabled
											toplabel
											maxlength="20"
										></pui-text-field>
									</v-col>
									<!-- AGENTEMAIL -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-text-field
											:id="`agentemail-securityforms`"
											v-model="model.agentemail"
											:label="$t('securityforms.agentemail')"
											disabled
											toplabel
											maxlength="70"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-field-set :title="$t('securityforms.fieldset.cso')">
								<v-row dense>
									<!-- CSOFAMILYNAME -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`csofamilyname-securityforms`"
											v-model="model.csofamilyname"
											:label="$t('securityforms.csofamilyname')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											required
											toplabel
											maxlength="50"
										></pui-text-field>
									</v-col>
									<!-- CSOGIVENNAME -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`csogivenname-securityforms`"
											v-model="model.csogivenname"
											:label="$t('securityforms.csogivenname')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											required
											toplabel
											maxlength="50"
										></pui-text-field>
									</v-col>
									<!-- CSOPHONE -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`csophone-securityforms`"
											v-model="model.csophone"
											:label="$t('securityforms.csophone')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
											maxlength="20"
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<!-- CSOFAX -->
										<pui-text-field
											:id="`csofax-securityforms`"
											v-model="model.csofax"
											:label="$t('securityforms.csofax')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
											maxlength="20"
										></pui-text-field>
									</v-col>
									<!-- CSOEMAIL -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-text-field
											:id="`csoemail-securityforms`"
											v-model="model.csoemail"
											:label="$t('securityforms.csoemail')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
											maxlength="70"
											:rules="emailRules"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-field-set :title="$t('securityforms.fieldset.portcall')">
								<v-row dense
									><v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`securityforms-portofarrival`"
											attach="securityforms-portofarrival"
											:label="$t('securityforms.portofarrival')"
											toplabel
											v-model="model.portcall"
											modelName="mports"
											disabled
											reactive
											:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
											:itemsToSelect="itemsToSelectArrivalPort"
											:itemValue="['countrycode', 'portcode']"
											:itemText="(item) => `${item.locode} - ${item.portdescription}`"
											:order="{ locode: 'asc' }"
										></pui-select>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`securityforms-portfacilitygisiscode`"
											attach="securityforms-portfacilitygisiscode"
											:label="$t('securityforms.portfacilitygisiscode')"
											toplabel
											v-model="model.portcall"
											modelName="vlupmportfacilities"
											disabled
											reactive
											:modelFormMapping="{
												countrycode: 'countrycode',
												portcode: 'portcode',
												portfacilitygisiscode: 'portfacilitygisiscode'
											}"
											:itemsToSelect="itemsToSelectPortFacilities"
											:itemValue="['countrycode', 'portcode', 'portfacilitygisiscode']"
											:itemText="
												(item) =>
													`${item.countrycode}${item.portcode}-${item.portfacilitygisiscode} - ${item.portfacilityname}`
											"
											:order="{ countrycode: 'asc' }"
										></pui-select>
									</v-col>
									<!-- PURPOSE. NMSWG-1511. Nuevo campo purpose en formulario y BD -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" v-if="!this.isCreatingElement">
										<pui-select
											id="`securityforms-purpose`"
											attach="securityforms-purpose"
											:label="$t('securityforms.purposecall')"
											:disabled="this.model.docstatusid != '1'"
											toplabel
											clearable
											required
											v-model="model"
											modelName="mportcallpurposes"
											:itemsToSelect="itemsToSelectpurpose"
											:modelFormMapping="{ portcallpurposecode: 'purpose' }"
											itemValue="portcallpurposecode"
											:itemText="(item) => `${item.purposedesc}`"
											reactive
										></pui-select>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" v-else>
										<pui-select
											id="`securityforms-purposecall`"
											attach="securityforms-purposecall"
											:label="$t('securityforms.purposecall')"
											toplabel
											clearable
											required
											v-model="model.portcall"
											modelName="mportcallpurposes"
											:itemsToSelect="itemsToSelectpurposecode"
											:modelFormMapping="{ portcallpurposecode: 'portcallpurposecode' }"
											itemValue="portcallpurposecode"
											:itemText="(item) => `${item.purposedesc}`"
											reactive
											@change="updatePurpose($event)"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense style="margin-top: -15px">
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
										><pui-date-field
											:id="`securityforms-eta`"
											:label="$t('securityforms.eta')"
											toplabel
											time
											v-model="model.portcall.eta"
											disabled
										></pui-date-field
									></v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"></v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-field-set :title="$t('securityforms.fieldset.iscc')">
								<v-row dense>
									<!-- VALIDCERTIFICATE -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12" style="margin-top: 20px">
										<pui-select
											:id="`validcertificate-securityforms`"
											:label="$t('securityforms.validcertificate')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											required
											leftlabel
											v-model="model"
											reactive
											:itemsToSelect="validcertificateItemsToSelect"
											:modelFormMapping="{ value: 'validcertificate' }"
											itemText="text"
											itemValue="value"
											:items="codes"
										></pui-select>
									</v-col>
								</v-row>

								<v-row dense>
									<!-- INVALIDISSCREASONCODE -->
									<v-col v-show="model.validcertificate == 0" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<pui-select
											:id="`invalidisscreasoncode-securityforms`"
											:attach="`invalidisscreasoncode-securityforms`"
											:label="$t('securityforms.invalidisscreasoncode')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											leftlabel
											clearable
											modelName="vlupminvalidisscreasons"
											v-model="model"
											reactive
											:itemsToSelect="invalidisscreasoncodeItemsToSelect"
											:modelFormMapping="{ invalidisscreasoncode: 'invalidisscreasoncode' }"
											:itemValue="['invalidisscreasoncode']"
											:itemText="(item) => `${item.invalidisscreasoncode} - ${item.invalidisscreasondescription}`"
											:required="this.model.validcertificate != '1'"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-row>
										<!-- APPROVEDSECURITYPLAN-->
										<v-col v-show="model.validcertificate == 0" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-12 col-xl-12">
											<pui-select
												:id="`approvedsecurityplan-securityforms`"
												:attach="`approvedsecurityplan-securityforms`"
												:label="$t('securityforms.approvedsecurityplan')"
												:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
												required
												leftlabel
												v-model="model"
												reactive
												:itemsToSelect="approvedsecurityplanItemsToSelect"
												:modelFormMapping="{ value: 'approvedsecurityplan' }"
												itemValue="value"
												itemText="text"
												:items="codes"
											></pui-select>
										</v-col>
									</v-row>
									<!-- ISSCTYPE -->
									<v-col v-if="this.model.validcertificate == '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											:id="`issctype-securityforms`"
											:label="$t('securityforms.issctype')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											:required="this.model.validcertificate == '1'"
											toplabel
											v-model="model"
											reactive
											:itemsToSelect="issctypeItemsToSelect"
											:modelFormMapping="{ value: 'issctype' }"
											itemText="text"
											:items="items"
										></pui-select>
									</v-col>
									<v-col v-if="this.model.validcertificate == '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<!-- ISSCISSUERTYPE -->
										<pui-select
											:id="`isscissuertype-securityforms`"
											:attach="`isscissuertype-securityforms`"
											:label="$t('securityforms.isscissuertype')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
											clearable
											v-model="model"
											reactive
											:itemsToSelect="isscissuertypeItemsToSelect"
											:modelFormMapping="{ value: 'isscissuertype' }"
											itemValue="value"
											itemText="text"
											:items="issuertype"
											:required="this.model.validcertificate == '1'"
										></pui-select>
									</v-col>

									<!--<v-col
										v-show="model.validcertificate == '1'"
										class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
										style="margin-top: 20px"
									>
									</v-col>-->
									<!-- ISSCISSUER -->
									<v-col v-if="this.model.validcertificate == '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`isscissuer-securityforms`"
											v-model="model.isscissuer"
											:label="$t('securityforms.isscissuer')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
											maxlength="256"
											:required="this.model.validcertificate == '1'"
										>
										</pui-text-field>
									</v-col>

									<v-col v-if="this.model.validcertificate == '1'" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<!-- ISSCEXPDATE -->
										<pui-date-field
											:id="`isscexpdate-securityforms`"
											v-model="model.isscexpdate"
											:label="$t('securityforms.isscexpdate')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
											:required="this.model.validcertificate == '1'"
										></pui-date-field>
									</v-col>
								</v-row>
								<v-row dense style="margin-top: -15px">
									<!-- SECURITYLEVELCODE -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-select
											:id="`securitylevelcode-securityforms`"
											:attach="`securitylevelcode-securityforms`"
											:label="$t('securityforms.securitylevelcode')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											required
											toplabel
											clearable
											modelName="vlupmsecuritylevels"
											v-model="model"
											reactive
											:itemsToSelect="securitylevelcodeItemsToSelect"
											:modelFormMapping="{ securitylevelcode: 'securitylevelcode' }"
											:itemValue="['securitylevelcode']"
											:itemText="(item) => `${item.securityleveldescription}`"
										></pui-select>
									</v-col>
									<!-- SHIPLOCATION -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-text-field
											:id="`shiplocation-securityforms`"
											v-model="model.shiplocation"
											:label="$t('securityforms.shiplocation')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
											maxlength="256"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>

					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<nsw-editionable-table
								formComponent="lastcallsformsheet"
								:tableData="model.listVlastcalls"
								:itemsPerPage="100"
								:myColumns="myColumnsLastcalls"
								:disableEditing="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							>
							</nsw-editionable-table>
						</v-col>
					</v-row>

					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<nsw-editionable-table
								formComponent="shipactivitiesformsheet"
								:tableData="model.listVshipactivities"
								:itemsPerPage="100"
								:myColumns="myColumnsshipactivities"
								:disableEditing="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
							>
							</nsw-editionable-table>
						</v-col>
					</v-row>

					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-field-set :title="$t('securityforms.fieldset.cargo')">
								<v-row dense>
									<!-- IMOFALFORM1ID-->
									<v-col
										v-if="model.imofalform1 && model.imofalform1.cargodescription != null"
										class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
									>
										<pui-text-area
											:id="`imofalform1id-securityforms`"
											v-model="model.imofalform1.cargodescription"
											:label="$t('securityforms.imofalform1id')"
											disabled
											toplabel
										></pui-text-area>
									</v-col>
									<v-col v-else class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<pui-text-area
											:id="`cargodescription-securityforms`"
											v-model="model.cargodescription"
											:label="$t('securityforms.cargodescription')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
										></pui-text-area>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- DANGEROUSCARGO -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<pui-select
											:id="`dangerouscargo-securityforms`"
											:attach="`dangerouscargo-securityforms`"
											:label="$t('securityforms.dangerouscargo')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											required
											leftlabel
											v-model="model"
											reactive
											:itemsToSelect="dangerouscargoItemsToSelect"
											:modelFormMapping="{ value: 'dangerouscargo' }"
											itemValue="value"
											itemText="text"
											:items="codes"
										></pui-select>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-field-set :title="$t('securityforms.fieldset.other')">
								<v-row dense>
									<!-- SECURITYMATTER -->
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<pui-text-area
											:id="`securitymatter-securityforms`"
											v-model="model.securitymatter"
											:label="$t('securityforms.securitymatter')"
											:disabled="this.model.docstatusid == '2' || this.model.docstatusid == '3'"
											toplabel
										></pui-text-area>
									</v-col> </v-row
							></pui-field-set>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer>
				<nsw-imo-form-footer-btns
					:formDisabled="(this.model.docstatusid == '3' && !isCreatingElement) || !havePermissions(['WRITE_SECURITYFORMS'])"
					:saveDisabled="saving"
					:value="model"
					:saveAndUpdate="saveAndUpdate"
					:showNext="false"
					:showDraft="this.model.docstatusid != 2"
					:showSubmit="this.model.docstatusid != 2"
					:showNewVersion="false"
					modelName="securityforms"
					:save="save"
					:back="back"
				></nsw-imo-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import securityformsActions from './SecurityformsActions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';
import { STATUS_SUBMITTED_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';
import { STATUS_DRAFT_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';

export default {
	name: 'securityforms-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'securityforms',
			actions: securityformsActions.actions,
			pkAttribute: 'securityformid',
			selectedOption: '1',
			radio: '',
			keyPressCount: 0,
			statusSubmitted: STATUS_SUBMITTED_DESC,
			statusDraft: STATUS_DRAFT_DESC,
			disabled: true,
			codes: [
				{
					text: this.$t('imofalform3items.yes'),
					value: '1'
				},
				{
					text: this.$t('imofalform3items.no'),
					value: '0'
				}
			],
			issuertype: [
				{
					text: this.$t('securityforms.recognsecorga'),
					value: '1'
				},
				{
					text: this.$t('securityforms.government'),
					value: '0'
				}
			],
			items: [
				{
					text: this.$t('securityforms.full'),
					value: '0'
				},
				{
					text: this.$t('securityforms.interim'),
					value: '1'
				}
			],
			myColumnsLastcalls: [
				'callstartdate',
				'callenddate',
				'countrydesc',
				'portdescription',
				'gisiscode',
				'portfacilityname',
				'securitylevelcode',
				'measuredescription'
			],
			myColumnsshipactivities: ['shipactivitystartdate', 'shipactivityenddate', 'location', 'purposedesc', 'measuredescription'],
			emailRules: [(v) => !v || /.+@.+\..+/.test(v) || 'Enter a valid email address']
		};
	},
	watch: {
		'model.signaturelocation'() {
			if (this.model.signaturelocation != null) {
				this.radio = '1';
			} else {
				this.radio = '2';
				this.model.signaturelocation = null;
			}
		},
		radio() {
			if (this.radio == '2') {
				this.model.signaturelocation = null;
			}
		},
		'model.portcall.portcallpurposecode'() {
			if (this.model.purpose == null) {
				this.model.purpose = this.model.portcall.portcallpurposecode;
			}
		}
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-portcallid-securityforms', (newVal) => {
			var lupaModel = newVal.model;

			if (lupaModel !== null) {
				this.fillPortCallFields(lupaModel);
			} else {
				this.clearPortCallFields();
			}
		});
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			if (this.$router.currentRoute.path.startsWith('/portcalls/')) {
				localStorage.setItem('tabFromAction', 'securityinformation');
			}
			if (this.$route.params.parentpk) {
				this.model.portcallid = JSON.parse(atob(this.$route.params.parentpk)).portcallid;
			}
			if (this.model.purpose != null) {
				this.model.portcall.portcallpurposecode = this.model.purpose;
			}
		},
		handleKeyDown(event) {
			const isBackspace = event.key === 'Backspace';
			const isNumericKey = !isNaN(parseInt(event.key, 10));
			if (this.keyPressCount >= 7 && !isBackspace) {
				event.preventDefault();
			} else if (isBackspace && this.keyPressCount > 0) {
				this.keyPressCount--;
			} else if (!isBackspace && isNumericKey) {
				this.keyPressCount++;
			}
		},
		fillPortCallFields(lupaModel) {
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.grosstonnage = lupaModel.grosstonnage;
			this.model.portcall.mship.certregistrycountry = lupaModel.certregistrycountry;
			this.model.portcall.mship.certregistryport = lupaModel.certregistryport;
			this.model.portcall.mship.inmarsatcallnr = lupaModel.inmarsatcallnr;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.portcall.portfacilitygisiscode = lupaModel.portfacilitygisiscode;
			this.model.portcall.eta = lupaModel.eta;
			this.model.portcall.mship.owneroperator = lupaModel.owneroperator;

			this.model.portcall.portcallpurposecode = lupaModel.portcallpurposecode;
		},
		clearPortCallFields() {
			this.model.portcall.mship.imo = null;
			this.model.portcall.mship.shipname = null;
			this.model.portcall.countrycode = null;
			this.model.portcall.mship.callsign = null;
			this.model.portcall.mship.shiptypecode = null;
			this.model.portcall.mship.grosstonnage = null;
			this.model.portcall.mship.certregcountrycode = null;
			this.model.portcall.mship.certregportcode = null;
			this.model.portcall.mship.inmarsatcallnr = null;
			this.model.portcall.countrycode = null;
			this.model.portcall.portcode = null;
			this.model.portcall.portfacilitygisiscode = null;
			this.model.portcall.eta = null;
			this.model.portcall.portcallpurposecode = null;
			this.model.imofalform1.cargodescription = null;
			this.model.imofalform1.imofalform1id = null;
			this.model.docsExtraFields.issuer = null;
			this.model.docsExtraFields.expires = null;
			this.model.portcall.mship.owneroperator = null;
		},
		// NMSWG-1511. Nuevo campo purpose en formulario y BD
		updatePurpose(event) {
			this.model.purpose = event.portcallpurposecode;
		}
	},
	computed: {
		filterPortcalls() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portcallstatus', op: 'ne', data: 0 },
							{ field: 'portcallstatus', op: 'ne', data: 6 },
							{ field: 'portcallstatus', op: 'ne', data: 3 }
						]
					}
				]
			};
		},
		itemsToSelectFlag() {
			return [{ countrycode: this.model.portcall.countrycode }];
		},
		itemsToSelectShipType() {
			return [{ shiptypecode: this.model.portcall.mship.shiptypecode }];
		},
		itemsToSelectRegistryPort() {
			return [
				{
					countrycode: this.model.portcall.mship.certregistrycountry,
					portcode: this.model.portcall.mship.certregistryport
				}
			];
		},
		itemsToSelectArrivalPort() {
			return [{ countrycode: this.model.portcall.countrycode, portcode: this.model.portcall.portcode }];
		},
		itemsToSelectPortFacilities() {
			return [
				{
					countrycode: this.model.portcall.countrycode,
					portcode: this.model.portcall.portcode,
					portfacilitygisiscode: this.model.portcall.portfacilitygisiscode
				}
			];
		},
		itemsToSelectpurposecode() {
			return [{ portcallpurposecode: this.model.portcall.portcallpurposecode }];
		},
		// NMSWG-1511. Nuevo campo purpose en formulario y BD
		itemsToSelectpurpose() {
			return [{ portcallpurposecode: this.model.purpose }];
		},
		portcallidItemsToSelect() {
			return [{ portcallid: this.model.portcallid }];
		},
		validcertificateItemsToSelect() {
			return [{ value: this.model.validcertificate }];
		},
		issctypeItemsToSelect() {
			return [{ value: this.model.issctype }];
		},
		isscissuertypeItemsToSelect() {
			return [{ value: this.model.isscissuertype }];
		},
		invalidisscreasoncodeItemsToSelect() {
			return [{ invalidisscreasoncode: this.model.invalidisscreasoncode }];
		},
		approvedsecurityplanItemsToSelect() {
			return [{ value: this.model.approvedsecurityplan }];
		},
		securitylevelcodeItemsToSelect() {
			return [{ securitylevelcode: this.model.securitylevelcode }];
		},
		dangerouscargoItemsToSelect() {
			return [{ value: this.model.dangerouscargo }];
		},
		signaturelocationItemsToSelect() {
			return [{ locode: this.model.signaturelocation }];
		}
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-portcallid-securityforms');
	}
};
</script>
<style>
#validcertificate-securityforms > div > div > div.flex.xs12.sm6.md8.xl9 > div > div > div.v-input__slot {
	width: 150px;
	margin-left: 10%;
}

#approvedsecurityplan-securityforms > div > div > div.flex.xs12.sm6.md8.xl9 > div > div > div.v-input__slot {
	width: 150px;
	margin-left: 12%;
}
#approvedsecurityplan-securityforms > div > div > div.flex.xs12.sm6.md4.xl3 {
	min-width: max-content;
}
#validcertificate-securityforms > div > div > div.flex.xs12.sm6.md4.xl3 {
	min-width: max-content;
}
#shiplocation-securityforms > div > div:nth-child(1) > div > label {
	min-width: max-content;
}

#dangerouscargo-securityforms > div > div > div.flex.xs12.sm6.md8.xl9 > div > div > div.v-input__slot {
	width: 150px;
	margin-left: 10%;
}

#validcertificate-securityforms > div > div:nth-child(2) > div > div > div > div.v-input__slot {
	width: 150px;
}
#approvedsecurityplan-securityforms > div > div:nth-child(2) > div > div > div > div.v-input__slot {
	width: 150px;
}
#dangerouscargo-securityforms > div > div:nth-child(2) > div > div > div > div.v-input__slot {
	width: 150px;
}
</style>
